@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;800;900&display=swap');
@import "~@fontsource/cedarville-cursive/index.css"; // Weight 400.

// colors
$dark-blue: #001B2E;
$brick-blue: #294C60;
$brick-blue-transparent: #294c605e;
$peach: #FFC49B;
$peach-transparent: #ffc49b73;
$pinkish-white: #fff5f5;
$gray: #ADB6C4;
$pale-orange: #FFEFD3;

// fonts
h1 {
    font-size: 64px;
    font-family: "Cedarville Cursive";
    color: $pinkish-white;
    font-weight: 100;
    text-transform: unset;
    line-height: 64px;
}

h2 {
    font-family: "Cedarville Cursive";
    color: $pinkish-white;
    font-size: 42px;
}

@keyframes copied-text-notification{
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
} 

html {
    background: $dark-blue;
}

.App {
    text-align: center;
    font-family: 'Montserrat', sans-serif;
    display: flex;
    justify-content: center;

    .page-wrapper {
        max-width: 800px;
        margin: 0 20px;
    }    
}